import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import Skeleton from 'react-loading-skeleton'
import { Link, useLocation, use } from 'react-router-dom'
import { ReactComponent as IconYoutube } from '../../assets/icons/youtube.svg'
import { ReactComponent as IconReddit } from '../../assets/icons/reddit.svg'
import { ReactComponent as IconDot } from '../../assets/icons/dot.svg'
import { ReactComponent as IconHeart } from '../../assets/icons/heart.svg'
import { ReactComponent as IconShare } from '../../assets/icons/share.svg'
import ShareMenu from '../ShareMenu';
import axios from 'axios'


const ContentMainCard = (props) => {
    const { content, onClickContent } = props

    const location = useLocation()
    const background = location.state && location.state.background;
    const modalLevel = location.state && location.state.modalLevel;

    const [showShareLinks, setShowShareLinks] = useState(false)
    const [liked, setLiked] = useState(false)
    const [likes, setLikes] = useState(content.likes.user + content.likes.bot)


    const handleToggleShareLinks = () => {
        setShowShareLinks(!showShareLinks)
    }

    const handleToggleLike = () => {
        if (liked) {
            setLikes(likes - 1)
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/content/unlike`, {
                contentId: content._id
            })
            .then(response => {
            })
    
        } else {
            setLikes(likes + 1)
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/content/like`, {
                contentId: content._id
            })
            .then(response => {
            })
        }
        setLiked(!liked)
    }

    const openContent = (id, title) => {
        if (onClickContent) {
            onClickContent()
        }
        return '/item/' + id + '/' + title.replace(/[^a-zA-Z0-9 _]/g,'').toLowerCase().split(' ').slice(0, 8).join('_')
    }

    const openSource = (source) => {
        return '/source/' + source
    }   
    
    const getImage = () => {
        return <img className='content-card-main__image' src={content.image} alt={content.title}></img>
    }

    let image = content.image ? getImage() : <Skeleton/>
    let time = content.date ? <TimeAgo datetime={content.date} locale='vi'/> : <Skeleton/>
    let source = content.type == 'reddit' ? 'r/' + content.source[0].name : content.source[0].name

    let sourceImage = ''
    switch (content.type) {
        case 'reddit':
            sourceImage = <IconReddit/>
            break
        case 'youtube':
            sourceImage = <IconYoutube/>
            break
        default:
            sourceImage = <img src={`${process.env.PUBLIC_URL}/assets/icons/sources/news/${content.source[0].image}`}/>           
    }

    return (
        <div className='content-card-main' >
            <div className='content-card-main__media'>
                <Link                         
                    to={openContent(content._id, content.title)}
                    state={{ background: background || location, modalLevel: modalLevel ? modalLevel + 1 : 1 }}
                >
                    {image}
                </Link>
            </div>
            <div className='content-card-main__content'>
                <div className="content-card-main__details">
                    <div className='content-card-main__source'>
                        {sourceImage}
                        <Link to={() => openSource(content.source[0].name)}>
                            <span>{source}</span>
                        </Link>
                        <IconDot className="content-card-main__dot"/>
                        {time}

                    </div>
                    <Link 
                        to={openContent(content._id, content.title)}
                        state={{ background: background || location, modalLevel: modalLevel ? modalLevel + 1 : 1 }}
                    >
                        <h3>{content.title}</h3>
                    </Link>
                </div>
                {
                <div className="content-card-main__action">
                    <div className='content-card-main__like'>
                        <IconHeart 
                            className={liked ? "input--button input--button__selected" : "input--button"}
                            onClick={handleToggleLike}
                        />
                        {likes > 0 && <span>{ likes }</span>}
                    </div>
                    <IconShare 
                        className={showShareLinks ? "input--button input--button__selected" :  "input--button"}
                        onClick={handleToggleShareLinks}
                    />
                </div>
                }
                {
                    showShareLinks && 
                    <ShareMenu 
                        url={content.url}
                        title={content.title}
                    />
                }             
            </div>
        </div>
    )
}

ContentMainCard.propTypes = {
    content: PropTypes.object.isRequired,
    onClickContent: PropTypes.func
}

export default ContentMainCard