import { combineReducers } from 'redux'

import SearchReducer from "./SearchReducer"
import SessionReducer from "./SessionReducer"
import ModalReducer from "./ModalReducer"

const reducers = combineReducers({
    SearchReducer: SearchReducer,
    SessionReducer: SessionReducer,
    ModalReducer: ModalReducer,
})

export default reducers