import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chautari from '../Chautari';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const CategoryScreen = () => {
    const params = useParams()

    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [items, setItems] = useState([]);

    const categoryMeta = {
        'videos': {
            'title': 'Videos',
            'description': 'Latest video content from brilliant youtubers.',
            'keywords': '',
            'requestType':'youtube'
        },

        'community': {
            'title': 'Community',
            'description': 'Latest community content from awesome subreddits.',
            'keywords': '',
            'requestType':'reddit'
        },

        'news': {
            'title': 'News',
            'description': 'Latest news content from trusted outlets.',
            'keywords': '',
            'requestType':'news'
        }
    }

    const handleLoadMoreItems = () => {
        const date = items.length ? items[items.length-1].date : (new Date()).toISOString()        
        let requestURL =`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?type=${categoryMeta[params.type].requestType}&date=${date}&limit=12`
        axios.get(requestURL)
        .then((response) => {
            if (!response.data.length) { setHasMoreItems(false) }
            setItems(items.concat(response.data));  
        })
    }

    //When category type has changed.
    useEffect(()=>{
        setItems([])
        setHasMoreItems(true)
    }, [params.type])

    return (
        <div className="screen">
            <Helmet>
                <title>{categoryMeta[params.type].title}</title>
                <meta name="description" content={categoryMeta[params.type].description} />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1776043858630809"
                crossorigin="anonymous"></script>
            </Helmet>
            <Chautari
                loadMoreItems={handleLoadMoreItems}
                hasMoreItems={hasMoreItems}            
                items={items}
            />
        </div>
    )
}

export default CategoryScreen