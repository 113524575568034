import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ContentMainCard from './cards/ContentMainCard'
import ContentTiles from './ContentTiles'

import { setSearch, clearSearch } from "../redux/actions/SearchActions.js"
import { useSelector, useDispatch } from 'react-redux';
import Loader from './Loader'

import InfiniteScroll from "react-infinite-scroller";


const Chautari = (props) => {

    const searchConfig = useSelector(state => state.SearchReducer)
    const dispatch = useDispatch()
    const loadItemCount = 12


    const [contentItems, setContentItems] = useState([])
    const [searchContentItems, setSearchContentItems] = useState([])
    const [contentHasMore, setContentHasMore] = useState(true)


    const createContentItems = (content) => {
        return content.map((item, index) => 
            <ContentMainCard key={index} content={item}/>
        )
    }

    const getSearchContentItems = (query) => {
        console.log('getting search items..')
        const searchDate = searchContentItems.length ? searchContentItems[searchContentItems.length - 1].date : (new Date()).toISOString()
        const searchQuery = JSON.stringify([query])
        axios.get(`http://localhost:5000/api/content/query=${[searchQuery]}&date=${searchDate}&count=${loadItemCount}`)
        .then(response => {
            console.log(response.data)
            if (response.data.length < loadItemCount)
                setContentHasMore(false)
                const content = createContentItems(response.data)
                setSearchContentItems(content)

        })
    }

    useEffect(() => {
        const content = createContentItems(props.items)
        setContentItems(content)
    }, [props.items])


    useEffect(() => {
        if (searchConfig.query !== null) {
            getSearchContentItems(searchConfig.query)
        }
    }, [searchConfig])



    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={searchConfig.query === null ? props.loadMoreItems : getSearchContentItems}
            hasMore={searchConfig.query === null ? props.hasMoreItems : contentHasMore}
            loader={<Loader key={0}/>}
        >
            {
                searchConfig.query !== null &&
                <div className='chautari__results'>
                    <span className="">Found { searchContentItems.length } for "{ searchConfig.query }"</span>
                </div>
            }
            <ContentTiles
                items={searchConfig.query === null ? contentItems : searchContentItems}
            />
        </InfiniteScroll>

    )
}

export default Chautari