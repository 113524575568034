//reducer used to manage Modal state.
const initialSessionConfig = 
{
    theme: 'light',
}

const SessionReducer = (sessionConfig = initialSessionConfig, action) => {
    switch (action.type) {
        case 'SET_THEME':   
            console.log('setting theme to..', action.payload)
            return {...sessionConfig, theme: action.payload}
        default:
            return sessionConfig
    }
}
export default SessionReducer