//reducer used to manage Search state.
const initialConfig = 
{
    isActive: false,
    query: null,
}

const SearchReducer = (searchConfig = initialConfig, action) => {
    switch (action.type) {
        case 'SET_SEARCH':   
            return {...searchConfig, query: action.payload}
        case 'CLEAR_SEARCH':            
            return {...searchConfig, query: null}
        case 'ACTIVATE_SEARCH':            
            return {...searchConfig, isActive: true}
        case 'DEACTIVATE_SEARCH':            
            return {...searchConfig, isActive: false, query: null}
        default:
            return searchConfig
    }
}
export default SearchReducer