import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'

import RouteSwitch from './RouteSwitch'
import { Link } from 'react-router-dom'
import Navigation from '../component/Navigation'

import advert from "../assets/images/Wide-Skyscraper.jpg"

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import AdUnitTile from '../component/ads/AdUnitTile.js';


let isBottom = false;



const AppRouter = () => {


    const [ tags, setTags ] = useState([])
    const sessionConfig = useSelector(state => state.SessionReducer)

    const themes = {
        'light': {
            'background': '#ffffff',
            'text': '#000000',
            'accentLight': '#808080',
            'accentLighter': '#666666'
        },

        'dark': {
            'background': '#000000',
            'text': '#ffffff',
            'accentLight': '#808080',
            'accentLighter': '#666666'
        }
    }


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/tag/getAll`)
        .then(response => {            
            //sort tags aplphabetically by name.
            const tagsSorted = response.data.sort((a, b) => {
                if ( a.name.toLowerCase() < b.name.toLowerCase() ) return -1;                
                if ( a.name.toLowerCase() > b.name.toLowerCase() ) return 1;            
                return 0;                
            })
            setTags(tagsSorted)
        })
    }, [])

    /*
    
    window.onscroll = function(ev) {

        if ((window.scrollY) > 50) {
            if (isBottom == false) {
                document.getElementById('navigation').classList.toggle('navigation--lighten')
            }
            isBottom = true
        }
        else if (isBottom == true){
            document.getElementById('navigation').classList.toggle('navigation--lighten')

            isBottom = false
        }
    };
*/

    useEffect(() => {
        console.log('changing theme too..', sessionConfig.theme)
        const root = document.documentElement
        root.style.setProperty('--colour-background', themes[sessionConfig.theme].background);
        root.style.setProperty('--colour-text', themes[sessionConfig.theme].text);
        root.style.setProperty('--colour-accent-light', themes[sessionConfig.theme].accentLight);
        root.style.setProperty('--colour-accent-lighter', themes[sessionConfig.theme].accentLighter);

    }, [sessionConfig.theme])


    return (
        <BrowserRouter>
            <Helmet>
                <title>GeekCaffeine: Fresh Geeky Content from The Internet</title>
                <meta
                    name="description"
                    content="Grab your coffee and enjoy the latest tech content from notorious sources across the internet. All your favourite Youtubers, Subreddits and news sites mixed together in one place!"
                    data-react-helmet="true"
                />
            </Helmet>
            <div className='body__header'>
                <Navigation/>
            </div>
            <div className='body__content'>
                <div className='body__left'>
                    <div className='categories'>
                        {
                            tags.map((tag, index) => 
                                <Link 
                                    key={index} 
                                    className='categories__link' 
                                    to={`/tag/${tag.name}`}
                                >
                                    {`#${tag.name}`}
                                </Link>
                            )
                        }
                    </div>
                </div>
                <div className='body__center'>
                    <RouteSwitch />
                </div>
                <div className='body__right'>
                    <AdUnitTile/>
                    <div className="advert">
                        {/*<img src={advert} alt="advert"></img>*/}
                        <AdUnitTile/>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    )
}

export default AppRouter