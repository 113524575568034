import { 
    fetchContentById,
    fetchTrendingContentMix,
    postLikeContentById,
    postUnlikeContentById,
} from "./requests";

const getContentById = (id) => {
    return fetchContentById(id)
    .then((res) => res.data)
}

const getTrendingContentMix = (
    youtubeContentCount,
    youtubeContentFrom,
    redditContentCount,
    redditContentFrom,
    newsContentCount,
    newsContentFrom,
) => {
    return fetchTrendingContentMix(
        youtubeContentCount,
        youtubeContentFrom,
        redditContentCount,
        redditContentFrom,
        newsContentCount,
        newsContentFrom,
    )
    .then((res) => {
        const { videos, posts, news } = res
        //if content is undefined or not enough new content is returned -> skip
        let newItems = []
            
        if (
            videos.data === undefined || videos.data.length < youtubeContentCount
            || posts.data === undefined || posts.data.length < redditContentCount
            || news.data === undefined || news.data.length< newsContentCount
        ) { return newItems }


        for (let i=0; i<youtubeContentCount; i++) {
            newItems.push(videos.data[i])
        }
        
        for (let i=0; i<redditContentCount; i++) {
            newItems.push(posts.data[i])
        }
        
        for (let i=0; i<newsContentCount; i++) {
            newItems.push(news.data[i])
        }

        newItems.sort((a,b) => (
            parseInt(a.date.substr(14,2)) - parseInt(b.date.substr(14,2))
        ))

        return {
            itemDates: {
                youtube: videos.data[videos.data.length-1].date,
                reddit: posts.data[posts.data.length-1].date,
                news: news.data[news.data.length-1].date    
            },
            content: newItems
        }
    })
}

const likeContentById = (id) => postLikeContentById(id).then((res) => res.data)

const unlikeContentById = (id) => postUnlikeContentById(id).then((res) => res.data)

export {
    getContentById,
    getTrendingContentMix,
    likeContentById,
    unlikeContentById,
}