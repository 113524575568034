import React from 'react'

import {
    TwitterIcon,
    TwitterShareButton,
    RedditIcon,
    RedditShareButton,
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    EmailIcon,
    EmailShareButton
} from 'react-share'

const ShareMenu = (props) => {
    return (
            <div className="content-card-main__share-links">
            <TwitterShareButton 
                className="input--button"
                url={props.url}
                title={props.title}
            >
                <TwitterIcon/>
            </TwitterShareButton>
            <RedditShareButton
                className="input--button"
                url={props.url}
                title={props.title}                        
            >
                <RedditIcon/>
            </RedditShareButton>
            <FacebookShareButton
                className="input--button"
                url={props.url}
                title={props.title}                        
            >
                <FacebookIcon/>
            </FacebookShareButton>
            <WhatsappShareButton
                className="input--button"
                url={props.url}
                title={props.title}                        
            >
                <WhatsappIcon/>
            </WhatsappShareButton>
        </div>
    )
}

export default ShareMenu