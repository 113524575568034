import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat';
import TimeAgo from 'react-timeago'
import { ReactComponent as IconDot } from '../../assets/icons/dot.svg'
import { ReactComponent as IconYoutube } from '../../assets/icons/youtube.svg'
import { ReactComponent as IconReddit } from '../../assets/icons/reddit.svg'
import { ReactComponent as IconInternet } from '../../assets/icons/behance-network.svg'
import { openContent } from '../../shared/openContent'


const ContentCardSnippet = (props) => {

    const [isHighlighted, setIsHighlighted] = useState(false)

    const getContentSourceName = () => {
        return props.content.type == 'reddit' ? 'r/' + props.content.source[0].name : props.content.source[0].name
    }

    const getContentDatePublished = () => {
        return dateFormat(props.content.date, 'dddd, dd mmmm yyyy HH:mm')
    }

    const getContentTimeElapsed = () => {
        return <TimeAgo date={props.content.date}/>
    }

    const getContentSourceImage = () => {
        switch (props.content.type) {
            case 'reddit':
                return <IconReddit className='icon--medium'/>
            case 'youtube':
                return <IconYoutube className='icon--medium'/>
            default:
                return <IconInternet className='icon--medium'/>
        }
    }


    useEffect(() => {
        props.index === props.currentContentIndexView ? setIsHighlighted(true) : setIsHighlighted(false)
    }, [props.currentContentIndexView])


    
    return (
        <div className={isHighlighted ? "content-item-snippet content-item-snippet--highlighted" : "content-item-snippet"}>
            <div className='content-item-snippet__body'>
                <Link className='link-undecorated' to={() => openContent(props.content._id, props.content.title)}>
                    <h3 className='content-item__title'>{props.content.title}</h3>
                </Link>    
                <div className='content-item__source'>
                    {getContentSourceImage()}
                    <span>{getContentSourceName()}</span>
                    <IconDot className='icon--dot'/>
                    <span>{getContentTimeElapsed()}</span>
                </div>

            </div>
        </div>
    )
}

React.Component.propTypes = {
    index: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    currentContentIndexView: PropTypes.number.isRequired,
}

export default ContentCardSnippet