import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ContentTile from '../ContentTiles'
import Loader from '../Loader'
import axios from 'axios'

import { useParams } from 'react-router-dom'

const SearchScreen = (props) => {

    const [contentItems, setContentItems] = useState([])
    const [contentHasMore, setContentHasMore] = useState(true)
    const { query } = useParams()
    const loadItemCount = 12
    console.log(query)
    useEffect(() => {

    }, [])

    const getNextContent = () => {
        const searchDate = contentItems.length ? contentItems[contentItems.length - 1].date : (new Date()).toISOString()
        const searchQuery = JSON.stringify([query])
        axios.get(`http://localhost:5000/api/content/query=${[searchQuery]}&date=${searchDate}&count=${loadItemCount}`)
        .then(response => {
            console.log(response.data)
            if (response.data.length < loadItemCount)
                setContentHasMore(false)
            setContentItems(contentItems => [...contentItems, ...response.data])

        })
    }

    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={getNextContent}
            hasMore={contentHasMore}
            loader={<Loader key={0}/>}
        >
            <ContentTile items={contentItems}/>
        </InfiniteScroll>
    )
}

export default SearchScreen