//reducer used to manage Modal state.
const initialModalConfig = 
{
    isOpen: false,
    component: null
}

const ModalReducer = (modalConfig = initialModalConfig, action) => {
    switch (action.type) {
        case 'OPEN_MODAL':   
            return {...modalConfig, isOpen:true, component:action.payload}
        case 'CLOSE_MODAL':            
            return {...modalConfig, isOpen:false, component:null}
        default:
            return modalConfig
    }
}
export default ModalReducer