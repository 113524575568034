import React, { useState } from 'react'
import { NavLink,  useLocation } from 'react-router-dom'
import IconLogo from '../assets/icons/logo.svg'

import { ReactComponent as IconDarkMode } from '../assets/icons/night.svg'
import { ReactComponent as IconDayMode } from '../assets/icons/day.svg'
import { ReactComponent as IconClose } from '../assets/icons/close.svg'
import { ReactComponent as IconMenu } from '../assets/icons/menu.svg'
import { ReactComponent as IconDiamond } from '../assets/icons/diamond.svg'
import { ReactComponent as IconFire } from '../assets/icons/fire.svg'

//import IconDiamond from '../assets/icons/dimond.gif'
//import IconFire from '../assets/icons/fire.gif'
import { useMediaQuery } from 'react-responsive'
import SearchBar from './SearchBar'
import { useSelector, useDispatch } from 'react-redux';
import { activateSearch, deactivateSearch, setSearch, clearSearch } from "../redux/actions/SearchActions.js"
import { setTheme } from "../redux/actions/SessionActions.js"

const NavigationCompact = (props) => {

    const searchConfig = useSelector(state => state.SearchReducer)

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const toggleMenu = () => {
        document.getElementById('navigationBackdrop').classList.toggle('navigation-mini__menuBackdrop--open')
        document.getElementById('navigationMenu').classList.toggle('navigation-mini__menu--open')

        if (isMenuOpen) {
            setIsMenuOpen(false)
        } else {
            setIsMenuOpen(true)
        }
    }


    return (
        <div className='navigation-mini'>
            {
                !searchConfig.isActive ?
                    <div className='navigation-mini__top'>

                        {
                            isMenuOpen ? 
                            <IconClose className='button--icon' onClick={toggleMenu}/> :
                            <IconMenu className='button--icon' onClick={toggleMenu}/>
                        }                        
                        <NavLink to='/'>
                            <div className='navigation-mini__logo'>
                                <div className="navigation-mini__logo__logo">
                                    <img className="logo" src={IconLogo}></img>
                                </div>
                                <span className='navigation__logo navigation__logo--text'></span>

                            </div>
                        </NavLink>
                        <SearchBar/>
                    </div>  
                : 
                <div className='navigation-mini__top'>
                    <SearchBar/>
                </div>
            }
            <div id='navigationBackdrop' className='navigation-mini__menuBackdrop' onClick={toggleMenu}>
            </div>
          
            <div id='navigationMenu' className='navigation-mini__menu'>
                <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/trending' onClick={toggleMenu}><IconDiamond/>Trending</NavLink>
                <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/hot' onClick={toggleMenu}><IconFire/>Hot</NavLink>
                <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/videos' onClick={toggleMenu}>Videos</NavLink>
                <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/community' onClick={toggleMenu}>Community</NavLink>
                <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/news' onClick={toggleMenu}>News</NavLink>
                {props.getThemeIcon()}

            </div>
        </div>
    )
}


const NavigationLarge = (props) => {

    const searchConfig = useSelector(state => state.SearchReducer)

    const { pathname } = useLocation()



    return (
        <div id='navigation' className='navigation navigation-full'>
            <NavLink className='navigation__link' to='/'>
                <div className='navigation__logo'>
                    <div className="navigation__logo__logo">
                        <img className="logo" src={IconLogo}></img>
                    </div>
                    <span className='navigation__logo navigation__logo--text'>GeekCaffeine</span>
                </div>
            </NavLink>
            <div className='navigation-full__right'>
                {
                    !searchConfig.isActive &&
                    <div className='navigation__links'>
                        {/*<img className='linkIcon linkIcon--diamond' src={IconDiamond}></img>*/}
                        <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/trending'
                            isActive={() => ['/', '/trending'].includes(pathname)}>
                            <IconDiamond/>
                            Trending
                        </NavLink>
                        {/*<img className='linkIcon linkIcon--fire' src={IconFire}></img>*/}

                        <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/hot'>
                            <IconFire/>
                            Hot
                        </NavLink>
                        <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/videos'>Videos</NavLink>
                        <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/community'>Community</NavLink>
                        <NavLink className='navigation__link' activeClassName='navigation__link--active' to='/content/news'>News</NavLink>
                    </div>
                }
                {props.getThemeIcon()}
                <SearchBar/>
            </div>
        </div>
    )
}




const Navigation = (props) => {
    const isSmall = useMediaQuery({ maxWidth: 1000 })
    const isLarge = useMediaQuery({ minWidth: 1000 })

    const sessionConfig = useSelector(state => state.SessionReducer)
    const dispatch = useDispatch()


    const getThemeIcon = () => {
        return sessionConfig.theme === 'light' ? 
            <IconDarkMode className='input--button' onClick={() => dispatch(setTheme('dark'))}/>
        : <IconDayMode className='input--button' onClick={() => dispatch(setTheme('light'))}/>

    }


    return (
        <div className='header'>
            {isLarge && <NavigationLarge 
                getThemeIcon={getThemeIcon}
            />}
            {isSmall && <NavigationCompact
                getThemeIcon={getThemeIcon}
            />}
        </div>
    )
}


export default Navigation