import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Chautari from '../Chautari'
import { Helmet } from 'react-helmet';


const HotScreen = () => {
    const params = useParams()

    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [items, setItems] = useState([]);

    const categoryMeta = {
        'title': 'Hot',
        'description': 'The freshest content for your geeky addictions!',
        'keywords': 'new,keywords'
    }


    const handleLoadMoreItems = () => {
        const date = items.length ? items[items.length-1].date : (new Date()).toISOString()
        const requestURL = `${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?date=${date}&limit=12`
        axios.get(requestURL)
        .then((response) => {
            if (!response.data.length) { setHasMoreItems(false) }
            setItems(items.concat(response.data));  
        })
    }

    useEffect(()=>{
        setItems([])
        setHasMoreItems(true)
    }, [params.type])

    return (
        <div className="screen">
            <Helmet>
                <title>{categoryMeta.title}</title>
                <meta name="description" content={categoryMeta.description} data-react-helmet="true"/>
            </Helmet>

            <Chautari
                loadMoreItems={handleLoadMoreItems}
                hasMoreItems={hasMoreItems}            
                items={items}
            />
        </div>
    )
}

export default HotScreen