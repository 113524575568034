import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import { ReactComponent as IconLink } from '../../assets/icons/out.svg'
import { ReactComponent as IconYoutube } from '../../assets/icons/youtube.svg'
import { ReactComponent as IconReddit } from '../../assets/icons/reddit.svg'
import { ReactComponent as IconInternet } from '../../assets/icons/behance-network.svg'
import { ReactComponent as IconDot } from '../../assets/icons/dot.svg'
import { ReactComponent as IconHeart } from '../../assets/icons/heart.svg'
import { ReactComponent as IconShare } from '../../assets/icons/share.svg'
import dateFormat from 'dateformat';
import altImg from '../../assets/icons/alt.svg'
import axios from 'axios'
import ShareMenu from '../ShareMenu';
import TimeAgo from 'react-timeago'

const ContentCardHuge = (props) => {

    const getContentSourceName = () => {
        return props.content.type == 'reddit' ? 'r/' + props.content.source[0].name : props.content.source[0].name
    }

    const getContentDatePublished = () => {
        return dateFormat(props.content.date, 'dddd, dd mmmm yyyy HH:mm')
    }

    const getContentTimeElapsed = () => {
        return <TimeAgo date={props.content.date}/>
    }

    const getContentDescription = () => {
        return props.content.description.length > 300 ? props.content.description.substr(0, 300) + '..': props.content.description
    }

    const getContentLikes = () => {
        return props.content.likes.user + props.content.likes.bot
    }

    const getContentMedia = () => {
        switch (props.content.type) {
            case 'reddit':
                const postId = props.content.url.replace("www.reddit.com", "www.redditmedia.com")
                return <iframe id="reddit-embed" src={`${postId}?ref_source=embed&amp;ref=share&amp;embed=true`} sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}} height="444" width="100%" scrolling="no"></iframe>
            case 'youtube':
                const videoId = props.content.url.substr(props.content.url.length-11)
                return <iframe className="youtube__embedded" src={"https://www.youtube.com/embed/" + videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            default:
                return <img className='content-item-huge__image' src={props.content.image} alt={altImg}></img>           
        }    
    }

    const getContentSourceImage = () => {
        switch (props.content.type) {
            case 'reddit':
                return <IconReddit className='content-item-huge__icon-source'/>
            case 'youtube':
                return <IconYoutube className='content-item-huge__icon-source'/>
            default:
                return <IconInternet className='content-item-huge__icon-source'/>

        }
    }
    
    const handleToggleShareLinks = () => {
        setProperties({...properties, showShareLinks: !properties.showShareLinks})
    }

    const handleLink = () => {
        window.open(props.content.url)
    }

    const handleToggleLike = () => {
        if (properties.liked) {
            setProperties({...properties, likes: properties.likes - 1, liked: !properties.liked})
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/content/unlike`, {
                contentId: props.content._id
            })

        } else {
            setProperties({...properties, likes: properties.likes + 1, liked: !properties.liked})
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/content/like`, {
                contentId: props.content._id
            })
        }
    }    

    const [properties, setProperties] = useState({
        sourceName: getContentSourceName(),
        sourceImage: getContentSourceImage(),
        datePublished: getContentDatePublished(),
        timeElapsed: getContentTimeElapsed(),
        description: getContentDescription(),
        media: getContentMedia(),
        likes: getContentLikes(),
        liked: false,
        showShareLinks: false,
    })

    return (
        <div className='content-item-huge'>
            <h2 className='content-item__title content-item-huge__title' onClick={handleLink}>{props.content.title}</h2>
            <div className='content-item-huge__source'>
                {properties.sourceImage}
                <div>
                    <span>{properties.sourceName}</span>
                    <span>{properties.datePublished} <IconDot className='icon--dot'/>{properties.timeElapsed}</span>
                </div>
            </div>
            {properties.media}
            <p className="content-item-huge__description">{properties.description}</p>
            <div className="content-item-huge__action">
                    
                <div className='content-item-huge__like'>
                    <IconHeart 
                        className={properties.liked ? "input--button input--button__selected" : "input--button"}
                        onClick={handleToggleLike}
                    />
                    {properties.likes > 0 && <span>{ properties.likes }</span>}
                </div>
                <IconLink className='input--button' onClick={handleLink}/>
                <IconShare 
                    className={properties.showShareLinks ? "input--button input--button__selected" :  "input--button"}
                    onClick={handleToggleShareLinks}
                />
            </div>                
            {
                properties.showShareLinks && 
                <ShareMenu 
                    url={props.content.url}
                    title={props.content.title}
                />
            }             


        </div>
    )
}

React.Component.propTypes = {
    key: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
}

export default ContentCardHuge