import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chautari from '../Chautari';
import { Helmet } from 'react-helmet';
import { getTrendingContentMix } from '../../libs/handler';

const youtubeContentCount = 4
const redditContentCount = 5
const newsContentCount = 3

let fetching = false

const HomeScreen = () => {

    const [contentItems, setContentItems] = useState([]); //2d array of article sets. 
    const [hasMore, setHasMore] = useState(true);
    const [itemDates, setItemDates] = useState({
        youtube: (new Date()).toISOString(),
        reddit: (new Date()).toISOString(),
        news: (new Date()).toISOString(),
    })
    
    
    const getNewContent = () => {
        if (fetching) return
        fetching = true
        getTrendingContentMix(
            youtubeContentCount,
            itemDates.youtube,
            redditContentCount,
            itemDates.reddit,
            newsContentCount,
            itemDates.news,        
        )
        .then(res => {
            const { itemDates, content } = res
            setItemDates(itemDates)
            setContentItems(contentItems.concat(content))
            setHasMore(content.length > 0)
        })
    }

    useEffect(() => {
        fetching = false
    }, [contentItems])

    return (
        <div className="screen">
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1776043858630809"
                crossorigin="anonymous"></script>
            </Helmet>
            <Chautari
                loadMoreItems={getNewContent}
                hasMoreItems={hasMore}            
                items={contentItems}
            />
        </div>

    )
}

export default HomeScreen