import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import AppRouter from './routes/AppRouter'
import * as serviceWorker from './serviceWorker';

import {createStore} from 'redux'
//connects global store to entire app.
import { Provider } from 'react-redux'
import reducers from './redux/reducers/reducers'

const store = createStore(reducers)

ReactDOM.render(
	<Provider store={store}>
		<AppRouter/>
	</Provider>,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
