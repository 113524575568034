import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import dateFormat from 'dateformat';
import TimeAgo from 'react-timeago'
import { ReactComponent as IconDot } from '../../assets/icons/dot.svg'
import Chautari from '../Chautari';
import ContentCardHuge from '../cards/ContentCardHuge'
import ContentCardSnippet from '../cards/ContentCardSnippet'
import { Helmet } from 'react-helmet';
import { ReactComponent as IconYoutube } from '../../assets/icons/youtube.svg'
import { ReactComponent as IconReddit } from '../../assets/icons/reddit.svg'
import { ReactComponent as IconInternet } from '../../assets/icons/behance-network.svg'


const currentDate = (new Date()).toISOString()

let dateSource = currentDate
let dateType = currentDate
let dateTag = currentDate
let dateAll = currentDate


const ContentDivider = () => {
    return (
        <div className='content-item-divider'>

        </div>
    )
}


const ContentScreen = (props) => {

    const params = useParams()

    const [hasMore, setHasMore] = useState(false);
    const [contentItems, setContentItems] = useState([])
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({
        'title': '',
        'description':''
    })

    const [currentContentIndexView, setCurrentContentIndexView] = useState(0)

    const maxFocuedItems = 5
    const contentHeight = 800
    let contentMainItems = []
    let contentPreviewItems = []

    //gets selected item and initial related items infomation.
    const setPageContent = () => {
        setContentItems([])
        setItems([])
        setHasMore(true)
        console.log(params.id)

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?id=` + params.id)
        .then(response => {
            let items = []
            
            items = items.concat(response.data[0])

            setMeta({
                'title': response.data[0].title,
                'description':response.data[0].description
            })
            

            console.log('id response', response.data[0])
            axios.all([
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?tags=${response.data[0].tags}&limit=${maxFocuedItems}`),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?source=${response.data[0].source[0]._id}&limit=${maxFocuedItems}`),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/samples/count=${maxFocuedItems}`),
            ])
            .then(axios.spread((suggestedItemsTags, suggestedItemsSource, suggestedItemsRandom) => {
                //console.log('s', suggestedItemsSource, 'r', suggestedItemsRandom, 't', suggestedItemsTags)
                for (let i=0; i<suggestedItemsTags.data.length; i++){
                    if (items.length == 2)
                    {
                        dateTag = items[items.length-1].date 
                        break
                    }
                    else if (!items.some(item => item._id === suggestedItemsTags.data[i]._id)) //check if items doesn't contains item with same _id 
                    { items.push(suggestedItemsTags.data[i]) }
                }
                for (let i=0; i<suggestedItemsSource.data.length; i++){
                    if (items.length == maxFocuedItems) 
                    {
                        dateSource = items[items.length-1].date 
                        break
                    }
                    else if (!items.some(item => item._id === suggestedItemsSource.data[i]._id)) 
                    { items.push(suggestedItemsSource.data[i]) }

                }
                for (let i=0; i<suggestedItemsRandom.data.length; i++){
                    if (items.length == maxFocuedItems)
                    { break }
                    else if (!items.some(item => item._id === suggestedItemsRandom.data[i]._id)) 
                    { items.push(suggestedItemsRandom.data[i]) }

                }
                setContentItems(items)
                console.log(items)
            }))
        })
    }

    contentItems.forEach((content, index) => {
        //console.log(content.title)
        if (index < maxFocuedItems) {
            contentPreviewItems.push(<ContentCardSnippet key={index} index={index} content={content} currentContentIndexView={currentContentIndexView}/>)
            contentMainItems.push(<ContentCardHuge key={index} content={content}/>)
            index < maxFocuedItems - 1 && contentMainItems.push(<ContentDivider/>)
        }
    })

    //loading more related items for select item.  
    const handleLoadMore = () => {
        if (!contentItems.length) { return }
        const mainItem = contentItems[0]

        axios.all([
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?date=${dateTag}&tags=${mainItem.tags}&limit=3`),
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?date=${dateSource}&source=${mainItem.source[0]._id}&limit=3`),
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?date=${dateType}&type=${mainItem.type}&limit=3`),
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?date=${dateAll}&limit=12`),

        ]).then(axios.spread((tagItems, sourceItems, typeItems, allItems) => {
            if (tagItems.data.length + sourceItems.data.length + typeItems.data.length + allItems.data.length < 12) { return setHasMore(false) }
            let newItems = Array.prototype.concat(tagItems.data, sourceItems.data, typeItems.data, allItems.data.slice(0, 12 - (tagItems.data.length + sourceItems.data.length + typeItems.data.length) - 1))
            
            dateTag = tagItems.data[tagItems.data.length-1] ? tagItems.data[tagItems.data.length-1].date : dateTag
            dateSource = sourceItems.data[sourceItems.data.length-1] ? sourceItems.data[sourceItems.data.length-1].date : dateSource
            dateType = typeItems.data[typeItems.data.length-1] ? typeItems.data[typeItems.data.length-1].date : dateType
            dateAll = allItems.data[allItems.data.length-1] ? allItems.data[allItems.data.length-1].date : dateAll
            
            setItems(items.concat(newItems))
        }))
    }

    const handleScroll = (e) => {
        const scrollAmount = e.target.scrollingElement.scrollTop
        if (scrollAmount < contentHeight * maxFocuedItems) {
            setCurrentContentIndexView(Math.floor(scrollAmount / (contentHeight - 100)  ))
        }
            //console.log(Math.floor(scrollAmount / contentHeight))
       
        
    }

    useEffect(() => {
        setPageContent()
    }, [params.id])


    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])

    return (
            <div className='content'>
                <Helmet>
                    <title>{meta.title}</title>
                    <meta
                        name="description"
                        content={meta.description}
                        data-react-helmet="true"
                    />
                </Helmet>
                <div className='content__timeline'>
                    {contentMainItems}
                </div>
                <div className='content__preview'>
                    <div className="preview-item--sticky">
                        {contentPreviewItems}
                    </div>
                </div>
                <div className='content__items'>
                    <Chautari
                        loadMoreItems={handleLoadMore}
                        hasMoreItems={hasMore}            
                        items={items}
                    />
                </div>
            </div>
 
    )
}

export default ContentScreen