import React from 'react'
import { ReactComponent as IconClose } from '../assets/icons/close.svg'
import { ReactComponent as IconSearch } from '../assets/icons/search.svg'

import { activateSearch, deactivateSearch, setSearch, clearSearch } from "../redux/actions/SearchActions.js"
import { useSelector, useDispatch } from 'react-redux';



const SearchBar = () => {

    const searchConfig = useSelector(state => state.SearchReducer)
    const dispatch = useDispatch()
   
    const handleActivateSearchBar = () => {
        dispatch(activateSearch())
    }

    const handleDeactivateSearchBar = () => {
        dispatch(deactivateSearch())
    }

    const handleQueryChange = e => {
        const serachQuery = e.target.value        
        if (serachQuery === '')
            dispatch(clearSearch())
        else
            dispatch(setSearch(serachQuery))
    }

    return (
        searchConfig.isActive ?
        <div className='search-bar'>
            <input type='text' placeholder="enter what you have in mind here.." onChange={handleQueryChange}></input>
            <IconClose className='input--button' onClick={handleDeactivateSearchBar}/>
        </div>:
        <IconSearch className='input--button' onClick={handleActivateSearchBar}/>
    )
}

export default SearchBar