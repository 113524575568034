import React, { useState, useEffect } from 'react'
import Mansonry from 'react-masonry-css'
import AdUnitTile from './ads/AdUnitTile.js';

const ContentTiles = (props) => {

    useEffect(() => {
        //props.items.push(<AdUnitTile/>)
    }, [])

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        800: 2,
        550: 1
    };


    return (
        <Mansonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
               
            index={1}
            content={{}}
            currentContentIndexView={1}
        >
            {props.items}
        </Mansonry>
    )
}

export default ContentTiles

/*
          <Mansonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"              
            >
                {content}
            </Mansonry>
*/