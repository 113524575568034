import React, { useState, useEffect } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Modal from 'react-modal'
import ContentMainCard from "../cards/ContentMainCard";
import { ReactComponent as IconClose } from '../../assets/icons/close.svg'
import { ReactComponent as IconBack } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as IconNext } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as IconHeart } from '../../assets/icons/heart.svg'
import { getContentById, likeContentById, unlikeContentById, fetchContentbyId } from "../../libs/handler";

import ContentFocus from "../ContentFocus";
import ShareMenu from '../ShareMenu';

const ContentCardFocusedModal = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate();

    const background = location.state && location.state.background;
    const modalLevel = location.state && location.state.modalLevel;


    const [liked, setLiked] = useState(false)
    const [content, setContent] = useState(null)
    const [loadingDelay, setLoadingDelay] = useState(true)

    const onExit = (e) => {
        const modalLevel = location.state && location.state.modalLevel;
        e.stopPropagation()
        navigate(-modalLevel)
    }

    const toggleLike = () => {
        if (!liked) {
            likeContentById(params.id)
            .then((res) => setContent({...content, selectedContent: res }))
        } else {
            unlikeContentById(params.id)
            .then((res) => setContent({...content, selectedContent: res }))
        }
        setLiked(!liked)
    }

    const renderSimmilarItems = () => {
        return content.similarContent.map(c => <ContentMainCard content={c} />)
    }

    const onNextContent = () => {
        const id = content.nextContent._id
        const title = content.nextContent.title
        const URL = '/item/' + id + '/' + title.replace(/[^a-zA-Z0-9 _]/g,'').toLowerCase().split(' ').slice(0, 8).join('_')
        navigate(URL, { replace: false, state: { background: background || location, modalLevel: modalLevel ? modalLevel + 1 : 1 }})
    }

    
    const onPreviousContent = () => {
        const id = content.previousContent._id
        const title = content.previousContent.title
        const URL = '/item/' + id + '/' + title.replace(/[^a-zA-Z0-9 _]/g,'').toLowerCase().split(' ').slice(0, 8).join('_')
        navigate(URL, { replace: false, state: { background: background || location, modalLevel: modalLevel ? modalLevel + 1 : 1 }})
    }

    useEffect(() => {
        if (params.id) {
            //initial delay
            setContent(null)
            setTimeout(() => {setLoadingDelay(false)}, 500);
            getContentById(params.id).then((res) => setContent(res) )
            
        }
    }, [params.id, modalLevel])

    return (
        <Modal
            isOpen={true}
            className="content-focus__body"
            overlayClassName="content-focus__backdrop"
            onRequestClose={onExit}
            preventScroll={true}
        >
            <div className="content-focus__action">
                <div>
                    <IconBack className="input--button" onClick={onPreviousContent} />
                    <IconNext className="input--button" onClick={onNextContent} />
                </div>
                <div>
                    <IconHeart className={liked ? "input--button input--button__selected":  "content-focus__action--like input--button"} onClick={toggleLike} />
                    {content?.selectedContent?.likes?.user > 0 && <span>{content?.selectedContent?.likes?.user}</span>} 
                    <ShareMenu 
                            url={content?.selectedContent && content?.selectedContent.url}
                            title={content?.selectedContent && content?.selectedContent.title}
                        />
                    <IconClose className="content-focus__action--close input--button" onClick={onExit} />
                </div>
            </div>
            <div className="content-focus__content-body">
                <ContentFocus isLoading={!content || loadingDelay} content={content?.selectedContent} />
                <div className="content-focus__similar">
                    <h2>You might like</h2>
                    <div className="content-focus__similar-grid" >
                    { content?.similarContent && renderSimmilarItems() }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ContentCardFocusedModal