import axios from "axios";

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

// get a single content id
const fetchContentById = (id) => {
    return axiosClient.get(`/content/getContentById=${id}`)
}

const fetchLatestYoutubeContent = (from, count) => {
    return axiosClient.get(`/content/getContent?type=youtube&date=${from}&limit=${count}`)
}

const fetchLatestRedditContent = (from, count) => {
    return axiosClient.get(`/content/getContent?type=reddit&date=${from}&limit=${count}`)
}

const fetchLatestNewsContent = (from, count) => {
    return axiosClient.get(`/content/getContent?type=news&date=${from}&limit=${count}`)
}

const fetchLatestContentByTag = (tag, count) => {
    return axiosClient.get(`/content/getContent?tags=${tag}&limit=${count}`)
}

const fetchLatestContentBySource = (source, count) => {
    return axiosClient.get(`/content/getContent?source=${source}&limit=${count}`)
}

const fetchSampleContent = (count) => {
    return axiosClient.get(`/content/samples/count=${count}`)
}

const postLikeContentById = (id) => {
    return axiosClient.post(`/content/like`, {
        contentId: id
    })
}

const postUnlikeContentById = (id) => {
    return axiosClient.post(`/content/unlike`, {
        contentId: id
    })
}

// gets latest content mixed
const fetchTrendingContentMix = (
    youtubeContentCount,
    youtubeContentFrom,
    redditContentCount,
    redditContentFrom,
    newsContentCount,
    newsContentFrom,
) => {
    return axios.all([
        fetchLatestYoutubeContent(youtubeContentFrom, youtubeContentCount),
        fetchLatestRedditContent(redditContentFrom, redditContentCount),
        fetchLatestNewsContent(newsContentFrom, newsContentCount),
    ]).then(axios.spread((videos, posts, news) => {
        return { videos, posts, news }
    }))
}


export {
    fetchContentById,
    fetchLatestYoutubeContent,
    fetchLatestRedditContent,
    fetchLatestNewsContent,
    fetchTrendingContentMix,
    fetchSampleContent,
    fetchLatestContentBySource,
    fetchLatestContentByTag,
    postLikeContentById,
    postUnlikeContentById,
}