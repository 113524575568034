import React from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'

import HomeScreen from '../component/screens/HomeScreen.js'
import TagScreen from '../component/screens/TagScreen.js'
import ContentScreen from '../component/screens/ContentScreen'
import CategoryScreen from '../component/screens/CategoryScreen'
import HotScreen from '../component/screens/HotScreen.js'
import SearchScreen from '../component/screens/SearchScreen'
import SourceScreen from '../component/screens/SourceScreen'
import ContentCardFocusedModal from '../component/screens/ContentCardFocusedModal'
import ContentCardFocusedFull from '../component/screens/ContentCardFocusedFull.js'

const RouteSwitch = () => {
    let location = useLocation()
    let background = location.state && location.state.background;
    //console.log('location..', location,background)

    return (
        <>
            <Routes location={ background || location }>
                <Route path='/' element={<HomeScreen />} exact={true}/>
                <Route path='/trending' element={<HomeScreen />} exact={true}/>
                <Route path='/hot' element={<HotScreen />} exact={true}/>
                <Route path='/search=:query' element={<SearchScreen />} exact={false}/>
                <Route path='/tag/:tag' element={<TagScreen />} exact={true}/>
                <Route path='/source/:source' element={<SourceScreen />} exact={true}/>
                <Route path='/item/:id/:title' element={<ContentScreen />}/>
                <Route path='/content/:type' element={<CategoryScreen />}/>
            </Routes>
            { background && (
                <Routes>
                    <Route path='/item/:id/:title' element={<ContentCardFocusedModal />}/>
                </Routes>
            )}
        </>
    )
}

export default RouteSwitch