import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Chautari from '../Chautari';
import { Helmet } from 'react-helmet';

let fetching = false

const TagScreen = () => {
    const params = useParams()

    const [contentItems, setContentItems] = useState([])
    const [contentHasMore, setContentHasMore] = useState(true)
    const [tag, setTag] = useState(null)

    const loadItemCount = 12

    const getNextContent = () => {
        if (tag == null) return

        console.log("called..")
        const searchDate = contentItems.length ? contentItems[contentItems.length - 1].date : (new Date()).toISOString()
        const searchQuery = JSON.stringify(tag.criteria)
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/content/query=${[searchQuery]}&date=${searchDate}&count=${loadItemCount}`)
        .then((response) => !response.data.length ? setContentHasMore(false) : setContentItems(contentItems.concat(response.data)))
  
    }

    const getTag = () => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/tag/getTagByName=${params.tag}`)
        .then(response => !response.data.length ? setContentHasMore(false) : setTag(response.data[0]))
    }

    useEffect(() => {
        setContentHasMore(true)
        setTag(null)
        setContentItems([]) //clear previous content when new tag is loaded
        getTag()
    }, [params.tag])


    return (
        <div>
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1776043858630809"
                crossorigin="anonymous"></script>
            </Helmet>
            <Chautari
                loadMoreItems={getNextContent}
                hasMoreItems={contentHasMore}            
                items={contentItems}
            />
        </div>
    )
}

export default TagScreen