import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chautari from '../Chautari';
import { Helmet } from 'react-helmet';

const SourceScreen = (props) => {

    const [contentItems, setContentItems] = useState([])
    const [contentHasMore, setContentHasMore] = useState(true)
    const [source, setSource] = useState(null)

    const handleLoadMoreItems = () => {
        if (source == null ) return

        //get next source content after last retrived content date.
        const date = contentItems.length ? contentItems[contentItems.length-1].date : (new Date()).toISOString()
        const requestURL = `${process.env.REACT_APP_API_ENDPOINT}/api/content/getContent?source=${source._id}&date=${date}&limit=12`
        axios.get(requestURL)
        .then((response) => !response.data.length ? setContentHasMore(false) : setContentItems(contentItems.concat(response.data)))
    }

    useEffect(() => {

        //get the matching source object from the source name.
        const requestURL = `${process.env.REACT_APP_API_ENDPOINT}/api/source/getSource?name=${props.match.params.source}`
        axios.get(requestURL)
        .then(response => !response.data.length ? setContentHasMore(false) : setSource(response.data[0]))
    }, [])

    return (
        <div className="screen">
            <Helmet>
                <title>{'Latest from ' + props.match.params.source}</title>
            </Helmet>

            <Chautari
                loadMoreItems={handleLoadMoreItems}
                hasMoreItems={contentHasMore}            
                items={contentItems}
            />
        </div>
    )
}

export default SourceScreen