//actions for Search reducer.

//set the search query. 
export const setSearch = (query) => {
    return {
        "type": "SET_SEARCH",
        "payload": query
    }
}

//clear the search query. 
export const clearSearch = () => {
    return {
        "type": "CLEAR_SEARCH",
    }
}

//clear the search query. 
export const activateSearch = () => {
    return {
        "type": "ACTIVATE_SEARCH",
    }
}


//clear the search query. 
export const deactivateSearch = () => {
    return {
        "type": "DEACTIVATE_SEARCH",
    }
}


