import React, { useState, useEffect } from "react"
import Modal from 'react-modal'
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import TimeAgo from "timeago-react";
import { ReactComponent as IconClose } from '../assets/icons/close.svg'
import { ReactComponent as IconYoutube } from '../assets/icons/youtube.svg'
import { ReactComponent as IconReddit } from '../assets/icons/reddit.svg'


const ContentFocus = (props) => {
    const { isLoading, content } = props
    return (
        isLoading ? <CardFocusedSkeleton /> : <CardFocusedContent content={content} />
    )
}

const CardFocusedContent = (props) => {
    const { content } = props
    
    //common function
    const getContentTimeElapsed = () => {
        return <TimeAgo date={content.date}/>
    }

    //common function
    const getSourceImage = () => {
        switch (props.content.type) {
            case 'reddit':
                return <IconReddit/>
            case 'youtube':
                return <IconYoutube/>
            default:
                return <img src={`${process.env.PUBLIC_URL}/assets/icons/sources/news/${props.content.source[0].image}`}/>           
        }
    }

    const onLinkClick = () => {
        window.open(props.content.url)
    }

    const refinedContent = {
        description: content.description.length > 200 ? content.description.substring(0, 200) + '...' : content.description
    }

    const getContentMedia = () => {
        switch (props.content.type) {
            case 'reddit':
                const postId = props.content.url.replace("www.reddit.com", "www.redditmedia.com")
                return <iframe id="reddit-embed" src={`${postId}?ref_source=embed&amp;ref=share&amp;embed=true`} sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}} height="444" width="100%" scrolling="no"></iframe>
            case 'youtube':
                const videoId = props.content.url.substr(props.content.url.length-11)
                return <iframe className="youtube__embedded" src={"https://www.youtube.com/embed/" + videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            default:
                return <img className='content-item-huge__image' src={props.content.image} ></img>           
        }    
    }

    return (
        <div className="content-focus__content" >
            <h1>{content.title}</h1>
            <div className="content-focus__source">
                <div>
                    {getSourceImage()}
                </div>
                <div style={{ width: "100%" }}>
                    <span>{content.source[0].name}</span>
                    <span>{getContentTimeElapsed()}</span>
                </div>
                <button className="content-focus__link-button input--button" onClick={onLinkClick}>Go to source</button>
            </div>
            {getContentMedia()}           
            <span className="content-focus__info" >{refinedContent.description}</span>
        </div>
    )
}

const CardFocusedSkeleton = () => {
    return (
        <div className="content-focus__content" >
            {<Skeleton className='content-focus-skeleton__title' count={1} />}
            <div className="content-focus__source">
                <div>
                    {<Skeleton circle={true} height={50} width={50}   />}
                </div>
                <div style={{ width: "100%" }}>
                    {<Skeleton className='content-focus-skeleton__source' />}
                    {<Skeleton className='content-focus-skeleton__source' />}
                </div>
                <Skeleton className='content-focus-skeleton__link-button'/>
            </div>                 
            {<Skeleton className='content-focus-skeleton__image' />}
            {<Skeleton className='content-focus__info' height={100} />}
        </div>
    )
}

export default ContentFocus